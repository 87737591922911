import React from "react"
import Layout from "../components/Layout"
import Menu from "../components/Menu"
import WhyContent from "../components/WhyContent"
import SEO from "../components/seo"
import { ThemeProvider } from "styled-components"
import General from "../styles/theme"

const WhyPage = () => (
  <ThemeProvider theme={General}>
    <Layout>
      <SEO title="Why Pyne?" />
     {/*  <Menu/> */}
      <WhyContent bgImg bgImgR/>
    </Layout>
  </ThemeProvider>
)

export default WhyPage
