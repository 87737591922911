import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image'

const AboutUsImg = () => {
  const { aboutUsImage } = useStaticQuery(
    graphql`
      query {
        aboutUsImage: file(relativePath: { eq: "team-pyne.png"}) {
          childImageSharp {
            fluid(maxWidth: 374, maxHeight: 361) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return <Img fluid={aboutUsImage.childImageSharp.fluid} style={{ width: '374px'}} alt="About Us"/>
}

export default AboutUsImg