import React from "react"
import * as S from "./styled"
import { withTrans } from "../../i18n/withTrans"
import AboutUsImg from "../AboutUsImg"

const AboutContent = ({ t, i18n }) => (
  <>
    <S.Section>
      <S.SectionOne>
        <S.Wrapper>
          <div className="container">
            <div className="columns is-desktop">
              <div className="column is-flex is-justify-content-center is-flex-direction-column">
                <S.Title>{t("navbar.aboutUs")}</S.Title>
                <S.Paragraph>
                  {t("aboutSection.paragraphOne")}<br/><br/>{t("aboutSection.paragraphTwo")}
                </S.Paragraph>
              </div>
              <div className="column is-flex is-justify-content-center is-align-items-center">
                <S.WrapperIMG>
                  <AboutUsImg/>
                </S.WrapperIMG>
              </div>
            </div>
          </div>
        </S.Wrapper>
      </S.SectionOne>
    </S.Section>
  </>
)

export default withTrans(AboutContent)
