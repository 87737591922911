import React from "react"
import * as S from "./styled"
import { withTrans } from "../../i18n/withTrans"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import WhyImg from "../WhyImg"
import WhyPeopleImg from "../WhyPeopleImg"
import AboutUS from "../AboutUs"
import Menu from "../Menu"

const WhyContent = ({ t, i18n, bgImg, bgImgR }) => (
  <>
    <S.Section>
      <S.SectionOne>
        <Menu/>
        <S.Wrapper>
          <div className="container">
            <div className="columns is-desktop">
              <div className="column is-flex is-justify-content-center is-flex-direction-column">
                <S.Title>{t("navbar.why")}</S.Title>
                <S.Paragraph>
                  <p className="content">{t("why.paragraphOne")}<br/><br/></p>
                  <p>{t("why.paragraphTwo")}<br/></p>
                  <span>{t("why.paragraphThree")}</span>
                </S.Paragraph>
              </div>
              <div className="column is-flex is-justify-content-center is-align-items-center">
                {/* <WhyImg/> */}
              </div>
            </div>
          </div>
        </S.Wrapper>
      </S.SectionOne>
{/*       <div className="container mt-6 mb-6">
        <div className="column">
          <S.WrapperPeopleImg>
            <WhyPeopleImg/>
          </S.WrapperPeopleImg>
        </div>
      </div> */}


      <div className="container mt-6 mb-6">
          <div className="columns">
            <div className="column">
              <S.WrapperIMG>
                <S.CardContent>
                  <p>{t("why.cardContentOne")}</p>
                </S.CardContent> 
              </S.WrapperIMG>
            </div>
            <div className="column">
            <S.WrapperIMG>
              <S.CardContent>
                <p>{t("why.cardContentTwoOne")}</p><br/>
                <p>{t("why.cardContentTwoTwo")}</p>               
              </S.CardContent>
            </S.WrapperIMG>
            </div>
            <div className="column">
            <S.WrapperIMG>
              <S.CardContent>
                <p>{t("why.cardContentThreeOne")}</p><br/>
                <p>{t("why.cardContentThreeTwo")}</p>
              </S.CardContent>
            </S.WrapperIMG>
            </div>
          </div>
      </div>
{/*       <div className="container mt-6">
      <div className="columns is-desktop mt-6">
        <div className="column">
          <S.TitleB>{t("why.titleBOne")}</S.TitleB>
          <S.ParagraphB>{t("why.paragraphBOne")}</S.ParagraphB>
          <S.ParagraphB>{t("why.paragraphBTwo")}</S.ParagraphB>
          <S.ParagraphB>{t("why.paragraphBThree")}</S.ParagraphB>
        </div>
        <div className="column">
          <S.TitleB>{t("why.titleBTwo")}</S.TitleB>
          <S.ParagraphB>{t("why.paragraphBFour")}</S.ParagraphB>
          <S.ParagraphB>{t("why.paragraphFive")}</S.ParagraphB>
          <S.ParagraphB>{t("why.paragraphBSix")}</S.ParagraphB>
          <S.ParagraphB>{t("why.paragraphBSeven")}</S.ParagraphB>
        </div>
      </div>
        <div className="columns is-desktop">
          <div className="column">
            <S.ParagraphTwo>
              {t("why.paragraphFour")}
            </S.ParagraphTwo>
          </div>
        </div>
      </div> */}
{/*       <div className="column is-flex is-justify-content-center is-align-items-center mt-6">
        <AniLink to="/contact">
          <S.Button>{t("introSection.buttonRequest")}</S.Button>
        </AniLink>
      </div>  */}
     {/* <AboutUS/> */}
    </S.Section>
  </>
)

export default withTrans(WhyContent)